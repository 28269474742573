import Api from '@/services/Index';

const getAll = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/esl/courses', data);
}

const get = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/esl/course/' + id);
}

const store = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/esl/course', data);
}

const update = async (id, data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put('/esl/course/' + id, data);
}

const del = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.delete('/esl/course/' + id);
}

export default {
    getAll,
    get,
    store,
    update,
    del,
}
